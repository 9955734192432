.home-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 2rem;
  background-color: #f5f7ff;
  gap: 2rem;
  flex-wrap: wrap;
}

.banner-content {
  flex: 1;
  max-width: 50%;
}

.banner-content h1 {
  font-size: 2.5rem;
  line-height: 1.4;
  color: #333;
}

.banner-content h1 span {
  display: block;
}

.banner-content .highlight {
  color: #4a4aff;
  font-weight: bold;
}

.banner-content p {
  font-size: 1.1rem;
  color: #555;
  margin: 1rem 0 2rem;
  line-height: 1.6;
}

.cta-buttons {
  display: flex;
  gap: 1rem;
}

.cta-primary {
  background-color: #4a4aff;
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.cta-primary:hover {
  background-color: #3737d0;
}

.cta-secondary {
  background-color: transparent;
  color: #4a4aff;
  border: 2px solid #4a4aff;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
}

.cta-secondary:hover {
  background-color: #4a4aff;
  color: #fff;
}

.banner-image {
  flex: 1;
  position: relative;
  max-width: 40%;
  display: flex;
  justify-content: center;
}

.main-image {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.stats-overlay {
  position: absolute;
  bottom: 10%;
  right: 5%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stat {
  background: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.stat h3 {
  font-size: 1.5rem;
  color: #4a4aff;
  margin: 0;
}

.stat p {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
}
