/* General Footer Styling */
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #0b0d19;
  color: white;
  padding: 40px 60px;
  border-radius: 20px 20px 0 0;
  font-family: "Arial", sans-serif;
}

/* Left Section Styling */
.footer-left {
  max-width: 40%;
}

.footer-left h2 {
  font-size: 28px;
  margin-bottom: 20px;
  line-height: 1.5;
}

.trial-button {
  padding: 12px 24px;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(90deg, #667eea, #764ba2);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.trial-button:hover {
  transform: scale(1.05);
}

/* Right Section Styling */
.footer-right {
  display: flex;
  gap: 40px;
}

.footer-column {
  max-width: 150px;
}

.footer-column h4 {
  font-size: 18px;
  margin-bottom: 15px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.footer-column ul li:hover {
  color: #667eea;
}

/* Social Media Section */
.footer-social {
  margin-top: 10px;
}

.footer-social h4 {
  margin-bottom: 10px;
}

.social-icons i {
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icons i:hover {
  transform: scale(1.2);
  color: #667eea;
}
