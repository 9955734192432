.services {
    padding: 3rem 2rem;
    background-color: #f5f7ff;
    text-align: center;
  }
  
  .services h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .services-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .service-item {
    flex: 1 1 calc(25% - 1rem);
    max-width: 300px;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  .service-item h3 {
    font-size: 1.5rem;
    color: #4a4aff;
    margin-bottom: 1rem;
  }
  
  .service-item p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  