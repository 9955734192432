/* Container for button and tooltip */
.whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Tooltip styling */
  .tooltip-text {
    background-color: #25d366;
    color: white;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    white-space: nowrap;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  /* Animation on hover */
  .whatsapp-container:hover .tooltip-text {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* WhatsApp button */
  .whatsapp-button {
    background-color: #25d366;
    border-radius: 50%;
    padding: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    animation: pulse 1.5s infinite;
  }
  
  /* Hover effect on the button */
  .whatsapp-button:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
  }
  
  /* WhatsApp icon inside the button */
  .whatsapp-icon {
    width: 50px;
    height: 50px;
  }
  
  /* Pulse animation for button */
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 8px rgba(37, 211, 102, 0.5);
    }
    50% {
      transform: scale(1.1);
      box-shadow: 0 0 16px rgba(37, 211, 102, 0.7);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 8px rgba(37, 211, 102, 0.5);
    }
  }
  