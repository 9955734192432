.stats {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 2rem 1rem;
    background-color: #ffffff;
    text-align: center;
    gap: 1.5rem;
  }
  
  .stat-item {
    flex: 1 1 calc(25% - 1rem);
    max-width: 200px;
    padding: 1rem;
    background-color: #f5f7ff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .stat-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  .stat-item h3 {
    font-size: 2rem;
    color: #4a4aff;
    margin-bottom: 0.5rem;
  }
  
  .stat-item p {
    font-size: 1rem;
    color: #555;
  }
  