@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.app {
  display: flex;
  flex-direction: column;
}

header, footer {
  background-color: #4a4aff;
  color: white;
  padding: 1rem;
  text-align: center;
}

.cta-button {
  background-color: #4a4aff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #3737e8;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
}

.hero-image img {
  max-width: 100%;
  height: auto;
}
