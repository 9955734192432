/* FAQ Container Styling */
.faq-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #000bd7;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}

.faq-title {
  font-size: 28px;
  text-align: center;
  margin-bottom: 30px;
  color: #ffffff;
}

/* FAQ Item Styling */
.faq-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faq-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px 20px;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.faq-item:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  transform: scale(1.02);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #333;
}

.faq-icon {
  font-size: 20px;
  font-weight: bold;
  color: #667eea;
}

/* Answer Styling with Animation */
.faq-answer {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.faq-item.active .faq-answer {
  max-height: 200px;
  opacity: 1;
}

/* Mobile Responsive */
@media (max-width: 600px) {
  .faq-container {
    padding: 15px;
  }

  .faq-title {
    font-size: 24px;
  }

  .faq-question {
    font-size: 16px;
  }

  .faq-answer {
    font-size: 14px;
  }
}
